<template lang="pug">
div
  van-row(type="flex" gutter='20')
    van-col(:span='5' style='padding-right:0px;z-index:0')
      van-sticky(:offset-top="46")
        van-sidebar(v-model='activeKey' @change="onChange")
          van-sidebar-item(
            v-for="(item, index) of types" :key="index"
            :title='item.name'
            )
          van-sidebar-item(title='项目' :key='3')
            
    van-col(:span='18' style='padding-left:5px; z-index:0;')
      van-grid(v-if="activeKey == 3" style='margin-top:15px;' :column-num="3" direction='horizontal' :border='true') 
        van-grid-item.category(v-for="(item, index) of projectPartyArr" :key="index2" icon="" :text="item.name" :to="item.to")
          span.van-grid-item__text {{item.name}}
      van-collapse(v-else v-model='collapseName' accordion)
        van-collapse-item(v-for="(item, index) of categories" :key="index" :title="item.name" :name='index'  v-if="!item.level && item.state=='on'")
          template(slot='title')
            span {{item.name}} 
            //- span(@click='onSelectSecondType(item)') {{item.name}} 
            //- h4.a(@click='onSelectSecondType(item)') {{item.name}} 
          //- van-row(v-for="(item, index) of categories" :key="index" v-if="!item.level && item.state=='on'" )  
          van-grid(:column-num="3" direction='horizontal' :border='true') 
            van-grid-item.category(v-for="(item2, index2) of item.children" :key="index2" icon="" :text="item2.name" :to="item2.to" v-if='item2.state=="on"')
              span.van-grid-item__text {{item2.name}}
     

  //-   van-col(:span="6")
  //-     van-sidebar(v-model='activeKey' @change="onChange")
  //-       van-sidebar-item(
  //-         v-for="item of types" :key="item.id"
  //-         :title='item.name'
  //-         )
  //-   //- 线上版本
  //-   //- van-col(:span="18")
  //-   //-   van-grid(square gutter="10" :column-num="3")
  //-   //-     van-grid-item.category(
  //-   //-       v-for="item of categories" :key="item.id"
  //-   //-       :to="item.to"
  //-   //-       )
  //-   //-       van-image(:src="item.icon_url")
  //-   //-       span.van-grid-item__text {{item.text}}
  //-   //- 新版准备
  //-   van-col(:span="18")
  //-     h4.a 分类1
  //-     van-grid(:column-num="3")
  //-       van-grid-item.category(
  //-         v-for="item of categories" :key="item.id"
  //-         :to="item.to"
  //-         )
  //-         span.van-grid-item__text {{item.text}}
  //-     h4.a 分类2
  //-     van-grid(:column-num="3")
  //-       van-grid-item.category(
  //-         v-for="item of categories" :key="item.id"
  //-         :to="item.to"
  //-         )
  //-         span.van-grid-item__text {{item.text}}
</template>

<script>
import { Sidebar, SidebarItem, Grid, GridItem } from 'vant'
const typesTT = require('../../../docs/商品分类数格式.json')
let init = false

let projectPromise = null
export default {
  components: {
    [Sidebar.name]: Sidebar,
    [SidebarItem.name]: SidebarItem,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem
  },
  data() {
    return {
      activeKey: 0,
      collapseName: 0,
      types1: [],
      types: [],
      categories: [
        // {
        //   icon: 'photo-o',
        //   text: '分类名称',
        //   to: {}
        // },
      ],
      projectPartyArr: [],
      
    }
  },
  computed: {
    hallData() {
      return this.$store.state.hallData
    }
  },
  methods: {
    SplitHallData() {
      if (!Object.keys(this.hallData).length) {
        return
      }
      let arrMenu = this.hallData.find(skin => skin.isActive === true)
      .header.find(h => h.name === '商品')
      .list.find(l => l.link === 'furnav')
      .nav || []
      let leftArr = arrMenu.filter((sub) => {
        if (sub.state === 'on') {
          return true
        }
      })
      // this.types = leftArr.map((type) => {
      //   return {
      //     id: type.id,
      //     name: type.norname,
      //     children: type.sp_type_id_rel_furniture_types.items
      //   }
      // })
      if (leftArr.length > 0) {
        this.activeKey = 0
      }
    },
    choiceType (obj) {
      if (!obj) return 
      this.categories = obj.children.map((child) => {
        return {
          level: false,
          // level: obj.name == '材质1',
          name: child.name,
          state: child.state,
          to: {
            name: 'search',
            query: child.query
          },
          children: child.children.map((item) => {
            return {
              name: item.name,
              state: item.state,
              to: {
                name: 'search',
                query: item.query
              }
            }
          })
        }
      })
      // if (obj.name != '材质') {
      //   this.categories.forEach(item => {
      //      item.children.unshift({
      //        name: '全部',
      //        state: 'on',
      //        to: item.to
      //      })
      //   });
      // }

      this.categories.forEach(item => {
          item.children.unshift({
            name: '全部',
            state: 'on',
            to: item.to
          })
      });
      
    },
    onChange(index) {
      let res = []
      if (index==3) {
        res = this.projectPartyArr
      } else {
        res = this.types[index]
        this.choiceType(res)
      }
      this.collapseName = -1
    },
    onSelectSecondType(obj) {
      let { name, query } = obj.to
      if (name) {
        this.$router.push({name, query})
      }
    },
    async initMaterialType() {
      try {
        let series = await this.getSeries()
        let matTypes = this.types[2]
        for (let i = 0; i < matTypes.children.length; i++) {
          let item = matTypes.children[i];
          const mat = item.query.mat
          let findSeries = series.filter(p => p.tags.length > 0 && p.tags.indexOf(mat) != -1)
          // findSeries = series
          item.children = findSeries.map(t => {
            return {
              name: t.companys_self_series_name,
              state: 'on',
              order: t.order,
              children: [],
              query: {
                series: t.id,
                mat: mat
              }
            }
          })
          // console.log(item)
        }
      } catch (error) {
        console.log('材质数据编辑错误')
      }
    },

    async getSeries() {
      let comId = this.$store.state.routeInfo.comId
      let res = await this.$api.GetSeries(comId)
      return res.data.items.map(p => {
        return {
          ...p,
          tags: p.furniture_material_tags ? p.furniture_material_tags.split(',') : []
        }
      })
    },

    // 获取项目分类
    async getProjectParty() {
      let params = {
        where: {
          state: 1
        },
        keys: 'id,icon_url,name'
      }
      if (!projectPromise) {
        projectPromise = this.$api.GetGmsProjectParty(params)
      }
      let res = await projectPromise
      this.projectPartyArr = res.data.items.map(item=>{
        return {
          image: item.icon_url,
          name: item.name,
          id: item.id,
          to: {
            name: 'search',
            query: {
              project: item.id
            }
          }
        }
      })
    },
  },
  async mounted() {
    this.SplitHallData()
    this.types = typesTT
    await this.getProjectParty()
    if (!init) {
      await this.initMaterialType()
      if (this.types.length === 0) {
        this.categories = []
      }
      init = true
    }
    // 设置材质的系列分类
    const {params: outParams} = this.$route
    if (outParams && outParams.type && outParams.type == 'category') {
      this.activeKey = outParams.index
      // this.onChange(outParams.index)
    } else {
      this.onChange(0)
    }
    this.$emit('on-nav', {
      leftText: '返回',
      rightText: '',
      leftArrow: true,
      rightArrow: false,
      onClickLeft: () => {
        this.$router.back(-1)
      },
      // onClickRight: () => {
      //   this.$router.push({name: 'home'})
      // }
    })
  },

  activated() {
    // this.$emit('on-nav', {
    //   leftText: '返回',
    //   leftArrow: true,
    //   onClickLeft: () => {
    //     this.$router.back(-1)
    //   }
    // })
    // // this.activeKey = 0
    // if (this.types.length === 0) {
    //   this.categories = []
    // }
  }
}
</script>

<style lang="less" scoped>
.category .van-grid-item {
  &__content {
    background-color:transparent !important;
    border-width: 0px !important;
    padding: 16px 0;
    &--surround::after {
      border-width: 0px !important;
    }
  }
}
.van-sidebar-item--select {
  background-color:#1989fa;
}

h4 {
  font-size :14px;
}


</style>